<template>
  <thead>
    <tr>
      <th class="column-icon"></th>
      <th class="width-46"><input type="checkbox" v-model="selectAll" :value="true" /></th>
      <th class="sort">№</th>
      <template v-for="column in tableHeader" :key="column.id">
        <th
          v-if="column.visible"
          @click="changeSorting(column.id)"
          :class="['sort', column.width ? `width-${column.width}` : '']"
        >
          <p :class="getClassSorting(column.id)" v-html="column.name"></p>
        </th>
      </template>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <template v-for="column in tableHeader" :key="column.id">
        <th v-if="column.visible">
          <template v-if="column.id == tableIdColumns.employeeName">
            <ComponentInput
              placeholder="Заявитель"
              mod="filter-small"
              v-model="filter.employeeName"
              clearButton
              @onClear="filter.employeeName = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.organizationName">
            <ComponentInput
              placeholder="Организация"
              mod="filter-small"
              v-model="filter.organizationName"
              clearButton
              @onClear="filter.organizationName = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.organizationAddress">
            <ComponentInput
              placeholder="Адрес"
              mod="filter-small"
              v-model="filter.organizationAddress"
              clearButton
              @onClear="filter.organizationAddress = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.phone">
            <ComponentInput
              placeholder="Телефон"
              mod="filter-small"
              type="number"
              v-model="filter.phone"
              clearButton
              @onClear="filter.phone = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.director">
            <ComponentInput
              placeholder="Директор"
              mod="filter-small"
              v-model="filter.director"
              clearButton
              @onClear="filter.director = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.registrationDate">
            <div class="horizontally-date">
              <dateComponent
                v-model="filter.registrationDateFrom"
                modClass="filter-small"
                :upperLimit="upperLimitRegistrationDateFrom"
              />
              <dateComponent
                v-model="filter.registrationDateTo"
                modClass="filter-small"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitRegistrationDateTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.registrationAddress">
            <ComponentInput
              placeholder="Место регистрации ЮЛ"
              mod="filter-small"
              v-model="filter.registrationAddress"
              clearButton
              @onClear="filter.registrationAddress = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.authorizedFund">
            <div class="horizontally-date">
              <ComponentInput
                placeholder="от"
                mod="filter-small"
                v-model="filter.authorizedFundFrom"
                clearButton
                @onClear="filter.authorizedFundFrom = ''"
              />
              <ComponentInput
                placeholder="до"
                mod="filter-small"
                v-model="filter.authorizedFundTo"
                clearButton
                @onClear="filter.authorizedFundTo = ''"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.created">
            <div class="horizontally-date">
              <dateComponent
                v-model="filter.createdFrom"
                modClass="filter-small"
                :upperLimit="upperLimitCreatedDateFrom"
              />
              <dateComponent
                v-model="filter.createdTo"
                modClass="filter-small"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitCreatedDateTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.tnvedGroupName">
            <ComponentInput
              placeholder="Группа товаров"
              mod="filter-small"
              v-model="filter.tnvedGroupName"
              clearButton
              @onClear="filter.tnvedGroupName = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedCode">
            <ComponentInput
              placeholder="Код ТН ВЭД"
              mod="filter-small"
              v-model="filter.tnvedCode"
              clearButton
              @onClear="filter.tnvedCode = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.measureTypeId">
            <SelectMultiComponent
              v-model="filter.measureTypeId"
              :options="measureTypeList"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.description">
            <ComponentInput
              placeholder="Описание"
              mod="filter-small"
              v-model="filter.description"
              clearButton
              @onClear="filter.description = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.statusId">
            <SelectMultiComponent
              v-model="filter.statusId"
              :options="getTtrStatusGroupOptions"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
              groups
            />
          </template>
          <template v-if="column.id == tableIdColumns.applicantGeneralInformation">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.applicantGeneralInformation"
              clearButton
              @onClear="filter.applicantGeneralInformation = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tradingOperation">
            <ComponentInput
              placeholder="Направление"
              mod="filter-small"
              v-model="filter.nsiTradingOperation"
              clearButton
              @onClear="filter.nsiTradingOperation = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.rates">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.specifications"
              clearButton
              @onClear="filter.specifications = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.purpose">
            <ComponentInput
              placeholder="Цель"
              mod="filter-small"
              v-model="filter.purpose"
              clearButton
              @onClear="filter.purpose = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.responsible">
            <ComponentInput
              placeholder="Ответственный сотрудник"
              mod="filter-small"
              v-model="filter.responsible"
              clearButton
              @onClear="filter.responsible = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.notes">
            <ComponentInput
              placeholder="Примечания"
              mod="filter-small"
              v-model="filter.notes"
              clearButton
              @onClear="filter.notes = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.organizationInfo">
            <ComponentInput
              placeholder="Данные об организации"
              mod="filter-small"
              v-model="filter.organizationInfo"
              clearButton
              @onClear="filter.organizationInfo = ''"
            />
          </template>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
  import { debounce } from 'lodash';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  import duplicateMethods from '../../mixins/duplicateMethods';
  import { TABLE_ID_INITIATIVE } from '../../utils/constants';

  export default {
    name: 'HeaderTable',
    emits: ['changeSorting', 'changeFilter', 'changeSelectAll'],
    mixins: [duplicateMethods],
    components: {
      ComponentInput,
      dateComponent,
      SelectMultiComponent,
    },
    props: {
      tableHeader: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        sortColumn: 0,
        sortOrder: 0,
        filter: {
          employeeName: '',
          organizationName: '',
          organizationAddress: '',
          phone: '',
          director: '',
          registrationDateFrom: '',
          registrationDateTo: '',
          registrationAddress: '',
          authorizedFundFrom: '',
          authorizedFundTo: '',
          createdFrom: '',
          createdTo: '',
          tnvedGroupName: '',
          tnvedCode: '',
          measureTypeId: '',
          description: '',
          statusId: '',
          applicantGeneralInformation: '',
          nsiTradingOperation: '',
          specifications: '',
          purpose: '',
          responsible: '',
          notes: '',
          organizationInfo: '',
        },
        selectAll: false,
        upperLimitTo: new Date(),
        tableIdColumns: TABLE_ID_INITIATIVE,
      };
    },
    computed: {
      upperLimitRegistrationDateFrom() {
        if (this.filter.registrationDateTo) {
          return moment(this.filter.registrationDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitRegistrationDateTo() {
        return moment(this.filter.registrationDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitCreatedDateFrom() {
        if (this.filter.createdTo) {
          return moment(this.filter.createdTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitCreatedDateTo() {
        return moment(this.filter.createdFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    methods: {
      getClassSorting(id) {
        if (this.sortColumn == id) {
          if (this.sortOrder == 0) return this.sortClassName.ascending;
          else if (this.sortOrder == 1) return this.sortClassName.descending;
        }
        return this.sortClassName.default;
      },

      changeSorting(id) {
        if (this.sortColumn == id) this.sortOrder = this.sortOrder == 0 ? 1 : 0;
        else this.sortOrder = 0;
        this.sortColumn = id;

        this.$emit('changeSorting', {
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
        });
      },

      changeFilter: debounce(function () {
        this.$emit('changeFilter', this.filter);
      }, 500),

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      clearFilter() {
        this.filter.employeeName = '';
        this.filter.organizationName = '';
        this.filter.organizationAddress = '';
        this.filter.phone = '';
        this.filter.director = '';
        this.filter.registrationDateFrom = '';
        this.filter.registrationDateTo = '';
        this.filter.registrationAddress = '';
        this.filter.authorizedFundFrom = '';
        this.filter.authorizedFundTo = '';
        this.filter.createdFrom = '';
        this.filter.createdTo = '';
        this.filter.tnvedGroupName = '';
        this.filter.tnvedCode = '';
        this.filter.measureTypeId = '';
        this.filter.description = '';
        this.filter.statusId = '';
        this.filter.applicantGeneralInformation = '';
        this.filter.tradingOperation = '';
        this.filter.rates = '';
        this.filter.purpose = '';
        this.filter.fullName = '';
        this.filter.notes = '';
        this.filter.organizationInfo = '';
      },
    },
    watch: {
      filter: {
        handler() {
          this.changeFilter();
        },
        deep: true,
      },

      selectAll(value) {
        this.$emit('changeSelectAll', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '../../styles/index.scss';
</style>
